<template>
  <div>
    <b-form class="mb-2" inline>
      <div :class="onlyActive ? 'w-100' : 'w-75 pr-1'">
        <b-form-input @blur="search" v-model="filter.text" class="w-100" :placeholder="$t('FILTER_SEARCH_LABEL', {catalog: $t('USER')}) | capitalize"
                      id="search-input"></b-form-input>
      </div>

      <b-form-select v-if="!onlyActive" @change="search" v-model="filter.status" class="w-25" id="status-select">
        <b-form-select-option :value="undefined">{{ $t('FILTER_ALL_LABEL', {gender: 'o', catalog: $t('USERS')}) | capitalize}}</b-form-select-option>
        <b-form-select-option :value="true">{{ $t('FILTER_ACTIVE_LABEL', {gender: 'o', catalog: $t('USERS')}) | capitalize}}</b-form-select-option>
        <b-form-select-option :value="false">{{ $t('FILTER_INACTIVE_LABEL', {gender: 'o', catalog: $t('USERS')}) | capitalize }}</b-form-select-option>
      </b-form-select>
    </b-form>
  </div>
</template>

<script>
import {CapitalFilter, Filter, VModel} from "@/mixins";

export default {
  name: "UserListFilter",
  props: ['onlyActive'],
  mixins: [VModel, CapitalFilter, Filter],
  data() {
    return {
      filter: {}
    }
  },
  methods: {
    search() {
      if(this.onlyActive) {
        this.filter.status = true;
      }
      this.$emit('search', this.filter);
    }
  }
}
</script>

<style scoped>

</style>
