<template>
  <div>
    <div class="pb-3">
      <!-- create button -->
      <b-button id="register-button" v-has-permission="'USER_CREATE_PERMISSION'" @click="add()" variant="dark">
        <b-icon-plus-circle></b-icon-plus-circle>
        <span class="ml-1">{{ $t('LIST_ADD_LABEL', {catalog: $t('USER')}) | capitalize }}</span>
      </b-button>
      <!-- create button -->

      <!-- refresh button -->
      <b-button class="ml-1" @click="refresh" variant="dark">
        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
        <span class="ml-1">{{ $t('LIST_REFRESH_LABEL') | capitalize }}</span>
      </b-button>
      <!-- refresh button -->

      <!-- clear button -->
      <b-button class="ml-1" @click="clear" variant="dark">
        <b-icon-x></b-icon-x>
        <span class="ml-1">{{ $t('LIST_FILTER_CLEAR_LABEL') | capitalize }}</span>
      </b-button>
      <!-- clear button -->

      <!-- Excel export -->
      <download-excel class="ml-1 btn btn-dark"
                      :data="items"
                      :fields="excelFields"
                      :worksheet="labels.users"
                      :name="labels.excel">
        <b-icon-file-earmark-excel class="mr-1"></b-icon-file-earmark-excel>
        {{ $t('LIST_EXCEL_DOWNLOAD_LABEL') | capitalize }}
      </download-excel>
      <!-- Excel export -->
    </div>
    <div>
      <!-- List page filter -->
      <user-list-filter :only-active="onlyActive" class="d-inline-block w-75" @search="setFilter"
                        v-model="filter"></user-list-filter>
      <!-- List page filter -->

      <!-- Page size -->
      <div class="d-inline-block pl-1 w-25">
        <page-size v-model="size"></page-size>
      </div>
      <!-- Page size -->
    </div>

    <!-- table -->
    <b-table
        id="users-table"
        ref="usersTable"
        @row-selected="handleSelection"
        striped
        hover
        bordered
        small
        table-variant="dark"
        selectable
        :select-mode="selectMode"
        v-model="items"
        :current-page="page"
        :sort-by="sort"
        :per-page="size"
        :busy.sync="isBusy"
        :fields="fields"
        :filter="filter"
        :items="listAll">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('USERS')}) }}</strong>
        </div>
      </template>

      <template #head(multiselect)="">
        <input :value="true" type="checkbox" v-model="allSelected" @click="selectAll"/>
      </template>

      <template #cell(multiselect)="data">
        <input :checked="isChecked(data)" @click="toggleRow(data)" type="checkbox"/>
      </template>

      <!-- username -->
      <template #head(username)="">
        <span>{{ labels.username | capitalize }}</span>
      </template>

      <template #cell(username)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.username }}</span>
        </div>
      </template>
      <!-- username -->

      <template #head(fullName)="">
        <span>{{ labels.fullName | capitalize }}</span>
      </template>

      <template #cell(fullName)="data">
        <span>{{ data.item.fullName }}</span>
      </template>

      <template #head(email)="">
        <span>{{ labels.email | capitalize }}</span>
      </template>

      <template #cell(date)="data">
        <span>{{ data.item.email }}</span>
      </template>

      <template #head(enabled)="">
        <span>{{ labels.enabled | capitalize }}</span>
      </template>

      <template #cell(enabled)="data">
        <div style="text-align: center">
          <b-icon-check-circle variant="success" v-if="data.item.enabled"></b-icon-check-circle>
          <b-icon-x-circle variant="danger" v-else></b-icon-x-circle>
        </div>
      </template>

      <template #head(options)="">
        <span>{{ $t('LIST_OPTIONS_LABEL') | capitalize }}</span>
      </template>

      <template #cell(options)="data">
        <div style="text-align: center">
          <b-dropdown size="sm" id="dropdown-1" right>
            <b-dropdown-item v-has-permission="'USER_UPDATE_PERMISSION'" @click="edit(data.item.id)">
              {{ $t('LIST_EDIT_LABEL') | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'USER_DELETE_PERMISSION'"
                             @click="remove(data.item.id, data.item.username)">
              {{ $t('LIST_DELETE_LABEL') | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'USER_PASSWORD_CHANGE_PERMISSION'"
                             @click="openChangePassword(data.item.id, data.item.username)">
              {{ $t('USER_PASSWORD_CHANGE_LABEL') | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'USER_DISABLE_PERMISSION'"
                             @click="disable(data.item.id, data.item.username)" v-if="data.item.enabled">
              {{ $t('LIST_DISABLE_LABEL') | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'USER_ENABLE_PERMISSION'"
                             @click="enable(data.item.id, data.item.username)" v-else>{{ $t('LIST_ENABLE_LABEL') }}
            </b-dropdown-item>
            <!-- v-has-permission="'USER_FILES_VIEW_PERMISSION'" -->
            <b-dropdown-item @click="showFileModal(data.item.id)">
              {{ $t('LIST_FILES_LABEL') | capitalize }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <!-- table -->
    <b-container fluid>
      <b-row>
        <b-col>
          <b-pagination
              v-model="page"
              :total-rows="totalRows"
              :per-page="size"
              pills
              aria-controls="profiles-table"
          ></b-pagination>
        </b-col>
        <b-col class="text-right">
          {{ (pageTotals.size ? $t('GRID_ROW_TOTAL', pageTotals) : $t('GRID_ALL_ROWS_TOTAL')) | capitalize }}
        </b-col>
      </b-row>
    </b-container>

    <b-modal size="xl" id="user-change-password-modal" :title="$t('USER_CHANGE_PASSWORD', {username}) | capitalize"
             hide-footer>
      <user-change-password
          :user-id="id"
          @password-changed="closeChangePassword()">
      </user-change-password>
    </b-modal>

    <b-modal id="digital-file-modal" :title="$t('FILE_MODAL_TITLE')" size="xl" hide-footer>
      <files-resume :id="this.id"></files-resume>
    </b-modal>
  </div>
</template>

<script>
import {Form, ProcessWithLoadingAndMessage, Table} from "@/mixins";
import Users from "@/users/index";
import UserChangePassword from "@/users/UserChangePassword";
import eventBus from "@/events";
import Constants from "@/constants";
import UserListFilter from "@/users/UserListFilter";
import PageSize from "@/components/PageSize";
import FilesResume from "@/files/FilesResume";
import Organization from "@/organization";

export default {
  name: "UserList",
  props: ['organizations', 'ownerOrganization', 'filterBy', 'selectMode', 'currentlySelected', 'onlyActive'],
  components: {PageSize, FilesResume, UserListFilter, UserChangePassword},
  mixins: [Table, Form, ProcessWithLoadingAndMessage],
  mounted() {
    eventBus.$on(Constants.EVENT_ON_USER_LIST, (totalElements) => {
      this.totalRows = totalElements;
    });
  },
  methods: {
    async getByOrganizations() {
      const ids = this.organizations.map(x => {
        const split = x.split('/');
        return split[split.length - 1]
      })
      if(this.ownerOrganization) {
        const split = this.ownerOrganization.split('/');
        ids.push(split[split.length - 1])
      }
      const resp = await Organization.usersByOrganizationIds(ids)
      return resp.data._embedded.users;
    },
    openChangePassword: async function (id, username) {
      this.id = id;
      this.username = username;
      this.$bvModal.show('user-change-password-modal');
    },
    closeChangePassword: async function () {
      this.$bvModal.hide('user-change-password-modal');
      this.sendMessage('USER_PASSWORD_CHANGED', {username: this.username});
    }
  },
  data() {
    return {
      username: '',
      labels: {
        username: this.$t('USER_USERNAME_LABEL'),
        fullName: this.$t('USER_FULL_NAME_LABEL'),
        email: this.$t('USER_EMAIL_LABEL'),
        enabled: this.$t('LIST_ENABLED_LABEL'),
        users: this.$t('USERS'),
        excel: this.$t('USERS') + '.xls',
      },
      registerPage: 'USER_REGISTER_TITLE',
      editPage: 'USER_EDIT_TITLE',
      selectedCount: 0,
      allSelected: false,
      originals: []
    }
  },
  computed: {
    listAll() {
      console.log('here');
      console.log(this.controller.listAll);
      return this.filterBy === 'organizations' ? this.getByOrganizations : this.controller.findAll
    },
    fields() {
      return [
        {key: 'multiselect', sortable: false, tdClass: 'selectTd'},
        {key: 'username', sortable: true, tdClass: 'customTd'},
        {key: 'fullName', sortable: true, tdClass: 'customTd'},
        {key: 'email', sortable: true, tdClass: 'customTd'},
        {key: 'enabled', sortable: true, tdClass: 'customTd'},
        {key: 'options', sortable: false, tdClass: 'optionsTd'}];
    },
    controller() {
      return Users;
    },
    tableRef() {
      return this.$refs.usersTable;
    },
    excelFields() {
      const fields = {};

      fields[this.labels.username] = 'username';
      fields[this.labels.fullName] = 'fullName';
      fields[this.labels.email] = 'email';
      fields[this.labels.enabled] = 'enabled';

      return fields;
    }
  }
}
</script>

<style>
.selectTd {
  width: 15px !important;
}

.nameTd {
  width: 75% !important;
}
</style>
